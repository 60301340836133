import React, { useContext } from 'react'
import AlertContext from '../../context/alert/alertContext'

export const Alert = () => {
  const alertContext = useContext(AlertContext)
  const { alert } = alertContext
  return (
    alert !== null && (
      <div className={`btn btn-block btn-${alert.type}`}>
        <i className="fas fa-info-circle"></i> {alert.msg}
      </div>
    )
  )
}

export default Alert
