import React, { Fragment } from 'react'

export const About = () => {
  return (
    <Fragment>
      <h1>About Us</h1>
      <p>This is an app for Github user searches</p>
      <p>Version 1.0.0</p>
    </Fragment>
  )
}

export default About
